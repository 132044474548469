import { useContext } from "react";
import appContext from "../context/appContext";
import Search from "./Search";
import { Box, Text } from "grommet";
import { RotateLeft } from "grommet-icons";
import { ButtonWithDrop } from "./Buttons";

const AreaOfInterest = () => {
  const { searchFeature } = useContext(appContext);

  if (!searchFeature) return null;

  return (
    <Box>
      <Text>{searchFeature.label}</Text>
      <Box align="start">
        <ButtonWithDrop
          icon={<RotateLeft size="16px"/>}
          label="Change location"
          dropContent={
            <Box pad="small" background={"dark-1"}>
              <Search compact />
            </Box>
          }
          dropAlign={{ top: "top", right: "left" }}
          dropProps={{ round: "xsmall" }}
          size='small'
          hoverIndicator="lightgrey"

        />
  
      </Box>
    </Box>
  );
};
export default AreaOfInterest;

