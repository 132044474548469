const actions = {
    SET_SEARCH_FEATURE_TO: 'SET_SEARCH_FEATURE_TO',
    SET_STATE_FILTER_TO: 'SET_STATE_FILTER_TO',
    REMOVE_GEO:'REMOVE_GEO',
    ADD_GEO:'ADD_GEO',
    CLEAR_GEOS:'CLEAR_GEOS',
    SET_POPUP_CONTENT:"SET_POPUP_CONTENT",
    SET_HOVERED_FEATURE_PROPS:"SET_HOVERED_FEATURE_PROPS",
    SET_BENCHMARK_TO:"SET_BENCHMARK_TO",
    SET_SEARCH_TYPE_TO:"SET_SEARCH_TYPE_TO",
    SET_MAP_EXTENT:"SET_MAP_EXTENT",
    SET_SELECTION_LAYER_TO:"SET_SELECTION_LAYER_TO",
    SET_REFVIS_BLM_OFFICES:"SET_REFVIS_BLM_OFFICES",
    SET_REFVIS_BLM_NLCS:"SET_REFVIS_BLM_NLCS",
    RETURN_TO_START:"RETURN_TO_START",
    SET_SEARCH_FOCUSED:"SET_SEARCH_FOCUSED",
    SET_IS_DOWNLOAD_MODAL_OPEN:"SET_IS_DOWNLOAD_MODAL_OPEN"
}
export default actions;