import { useContext } from "react";

import appContext from "../context/appContext";
import { useViewport } from "@headwaters-economics/web-shared";
// import HeSelect from "@headwaters-economics/web-shared/components/Select";
import { Box, Select } from "grommet";

const StateFilter = () => {
  const { screenSize } = useViewport();
  const { isSearchFocusedOnce, stateFilter, setStateFilter, searchType } =
    useContext(appContext);

  let style =
    screenSize === "mobile"
      ? { gridColumn: "1", gridRow: "3", paddingTop: "10px" }
      : { gridColumn: "2", gridRow: "1", paddingLeft: "10px" };
  if (screenSize === "mobile" && !isSearchFocusedOnce) {
    style.display = "none";
  }
  return (
    <Box background={"light-3"} round="xsmall">
      <Select
        options={[
          { value: "", label: "Any State" },
          { value: "1000", label: "Alabama" },
          { value: "2000", label: "Alaska" },
          { value: "4000", label: "Arizona" },
          { value: "5000", label: "Arkansas" },
          { value: "6000", label: "California" },
          { value: "8000", label: "Colorado" },
          { value: "9000", label: "Connecticut" },
          { value: "10000", label: "Delaware" },
          { value: "11000", label: "District of Columbia" },
          { value: "12000", label: "Florida" },
          { value: "13000", label: "Georgia" },
          { value: "15000", label: "Hawaii" },
          { value: "16000", label: "Idaho" },
          { value: "17000", label: "Illinois" },
          { value: "18000", label: "Indiana" },
          { value: "19000", label: "Iowa" },
          { value: "20000", label: "Kansas" },
          { value: "21000", label: "Kentucky" },
          { value: "22000", label: "Louisiana" },
          { value: "23000", label: "Maine" },
          { value: "24000", label: "Maryland" },
          { value: "25000", label: "Massachusetts" },
          { value: "26000", label: "Michigan" },
          { value: "27000", label: "Minnesota" },
          { value: "28000", label: "Mississippi" },
          { value: "29000", label: "Missouri" },
          { value: "30000", label: "Montana" },
          { value: "31000", label: "Nebraska" },
          { value: "32000", label: "Nevada" },
          { value: "33000", label: "New Hampshire" },
          { value: "34000", label: "New Jersey" },
          { value: "35000", label: "New Mexico" },
          { value: "36000", label: "New York" },
          { value: "37000", label: "North Carolina" },
          { value: "38000", label: "North Dakota" },
          { value: "39000", label: "Ohio" },
          { value: "40000", label: "Oklahoma" },
          { value: "41000", label: "Oregon" },
          { value: "42000", label: "Pennsylvania" },
          { value: "44000", label: "Rhode Island" },
          { value: "45000", label: "South Carolina" },
          { value: "46000", label: "South Dakota" },
          { value: "47000", label: "Tennessee" },
          { value: "48000", label: "Texas" },
          { value: "49000", label: "Utah" },
          { value: "50000", label: "Vermont" },
          { value: "51000", label: "Virginia" },
          { value: "53000", label: "Washington" },
          { value: "54000", label: "West Virginia" },
          { value: "55000", label: "Wisconsin" },
          { value: "56000", label: "Wyoming" },
        ]}
        value={stateFilter}
        onChange={(evt) => {
          setStateFilter(evt.value);
        }}
        dropHeight="medium"
        labelKey={"label"}
        placeholder="Any State"
        // valueKey={{ key: "value", reduce: false }}
        disabled={searchType==='he-state'}
      />
    </Box>
  );
};
export default StateFilter;
