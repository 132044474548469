import { useContext } from "react";
import appContext from "../context/appContext";

import GeoList from "@headwaters-economics/web-shared/components/GeoList";
import { Box, Text } from "grommet";
import { Download, Close } from "grommet-icons";
import AreaOfInterest from "./AreaOfInterest";
import { ColoredButton } from "./Buttons";

const SectionBreak = () => {
  return <Box border={{ color: "lightgrey" }} />;
};

const Sidebar = () => {
  const { selectedGeos, removeGeo, setIsDownloadModalOpen, clearGeos } = useContext(appContext);
  const selectedCounties = selectedGeos.filter(
    (geo) => geo.geoLevel === "he-county"
  );
  const selectedStates = selectedGeos.filter(
    (geo) => geo.geoLevel === "he-state"
  );
  return (
    <Box pad={{ top: "small", right: "small" }} width={{min:'325px'}}>
      <Box
        pad={"small"}
        round="xsmall"
        height={{ max: "large" }}
        overflow={"auto"}
        elevation="medium"
        background={"light-1"}
        width={{ max: "medium" }}
        border
        gap="medium"
      >
        <AreaOfInterest />
        <SectionBreak />
        {selectedGeos.length > 0 && (
          <Box width="xsmall" alignSelf="end">
            <ColoredButton
              label="Reset"
              reverse
              onClick={clearGeos}
              size="small"
              icon={<Close color="dark-1" size="small" />}
              // color="lightgrey"
              hoverIndicator="lightgrey"
            />
          </Box>
        )}
        {selectedGeos.length === 0 ? (
          <i>Select one or more states or counties to generate a report.</i>
        ) : (
          <Box gap="medium">
            {selectedCounties.length > 0 && (
              <Box>
                <Text size="small" color="brand">
                  Counties
                </Text>
                <GeoList
                  selectedGeos={selectedCounties}
                  removeGeo={(geoId) => removeGeo({ id: geoId })}
                />
              </Box>
            )}
            {selectedStates.length > 0 && (
              <Box>
                <Text size="small" color="brand">
                  States
                </Text>
                <GeoList
                  selectedGeos={selectedStates}
                  removeGeo={(geoId) => removeGeo({ id: geoId })}
                />
              </Box>
            )}
          </Box>
        )}
        <SectionBreak />
        <ColoredButton
          label="Download Profile"
          onClick={() => setIsDownloadModalOpen(true)}
          icon={<Download color="light-1" />}
          color="brand"
          hoverIndicator="#006eb7"
          pad="small"
        />
      </Box>
    </Box>
  );
};
export default Sidebar;
