import React, { useContext, useState } from "react";

import HeMapboxComponent, {
  Popup,
  CensusLayer,
} from "@headwaters-economics/web-shared/components/Map";
import { useViewport } from "@headwaters-economics/web-shared";

import { Source, Layer } from "react-map-gl";

import Legend from "./Legend";
import some from "lodash/some";

import appContext from "../../context/appContext";
import { Box, Text } from "grommet";

const SearchReferenceLayer = ({
  source,
  layerName,
  labelSource,
  labelLayerName,
  searchFeatureId,
}) => {
  return (
    <>
      <Layer
        id={source.replace("src_", "lyr_")}
        source-layer={layerName}
        source={source}
        type="line"
        paint={{
          "line-color": "rgb(254, 204, 92)",
          "line-width": 5,
        }}
        filter={["==", "id", searchFeatureId]}
      />
      <Layer
        id={source.replace("src_", "lyr_label_")}
        source-layer={labelLayerName}
        source={labelSource}
        type="symbol"
        paint={{
          "text-halo-color": "black",
          "text-halo-width": 2,
          "text-color": "rgb(254, 204, 92)",
          "text-halo-blur": 1,
        }}
        layout={{
          "text-allow-overlap": false,
          "text-field": "{name}",
          "text-transform": "uppercase",
          "text-letter-spacing": 0.1,
          "text-max-width": 12,
          "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
          "text-padding": 10,
          "text-size": 16,
          "text-optional": false,
        }}
        filter={["==", "id", searchFeatureId]}
      />
    </>
  );
};
const BlmReferenceLayer = ({
  source,
  layerName,
  labelSource,
  labelLayerName,
  color,
}) => {
  const isOffice = source.includes("office");
  return (
    <>
      <Layer
        id={source.replace("src_", "lyr_ref_")}
        source-layer={layerName}
        source={source}
        beforeId="housenum-label"
        type="line"
        paint={{
          "line-color": color,
          "line-width": 2,
        }}
      />
      <Layer
        id={source.replace("src_", "lyr_ref_label_")}
        source-layer={labelLayerName}
        source={labelSource}
        beforeId="country-label-lg"
        type="symbol"
        minzoom={6}
        paint={{
          "text-color": color,
        }}
        layout={{
          "text-allow-overlap": false,
          "text-field": "{name}",
          // "text-transform": "uppercase",
          "text-letter-spacing": 0.1,
          "text-max-width": 12,
          "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
          "text-padding": 10,
          "text-size": 12,
          "text-optional": true,
        }}
        filter={["==", "geo_level", isOffice ? "blm-office" : "blm-nlcs"]}
      />
    </>
  );
};

const Map = () => {
  const {
    selectedGeos,
    searchFeature,
    mapExtent,
    selectionLayer,
    refVis_blmOffices,
    refVis_blmNlcs,
    removeGeo,
    addGeo,
  } = useContext(appContext);
  const { screenSize } = useViewport();
  const [hoveredFtr, set_hoveredFtr] = useState(null);
  // const [cursorPosition, set_cursorPosition] = useState(null);

  // const onMousemove = useThrottle(
  //   (e) =>
  //     set_cursorPosition({ latitude: e.lngLat.lat, longitude: e.lngLat.lng }),
  //   200
  // );

  const PopupContent = () => {
    if (!hoveredFtr) return null;
    const addOrRemove = some(selectedGeos, { id: hoveredFtr.id })
      ? "remove"
      : "add";
    const clickOrTap = screenSize === "mobile" ? "Tap" : "Click";
    return (
      <Box pad={"xsmall"}>
        <Text><i>{hoveredFtr.properties.name}</i></Text>
        <Text size="small">
          {clickOrTap}
          {" to "}
          <Text size="small" weight={"bold"}>{addOrRemove}</Text>
        </Text>
      </Box>
    );
  };

  if (!searchFeature) return null;

  return (
    <Box fill>
      <HeMapboxComponent
        mapStyle="mapbox://styles/headmin/ckm4h3rhz9u2z17o0ytb2i8w3"
        extent={mapExtent}
        screenSize={screenSize}
        // onMousemove={onMousemove}
        zoomGeoIds={selectedGeos.map((sg) => sg.id)}
        // onMapLoad={map=>console.debug(map.current.getMap())}
      >
        <Legend />

        {hoveredFtr && (
          <Popup closeButton={false} closeOnClick={false}>
            <PopupContent />
          </Popup>
        )}

        <Source
          id={"ownership"}
          type="vector"
          url={"mapbox://headmin.0ryqrvg7"}
        />
        <Source
          id={"src_blm_offices"}
          type="vector"
          url={"mapbox://headmin.3g7d77ns"}
        />
        <Source
          id={"src_blm_nlcs"}
          type="vector"
          url={"mapbox://headmin.17xzq87j"}
        />
        <Source
          id={"src_blm_labels"}
          type="vector"
          url={"mapbox://headmin.a5jxrw86"}
        />

        <Layer
          id={"blmBackground"}
          source-layer={"all"}
          beforeId="hospital"
          source="ownership"
          type="fill"
          paint={{
            "fill-color": "rgba(240, 209, 117, 0.5)",
            "fill-outline-color": "transparent",
          }}
          filter={["==", "HE_Ownersh", "BLM"]}
        />

        <CensusLayer
          layerName={selectionLayer === "he-state" ? "state" : "county"}
          beforeId="housenum-label"
          onClick={(ftr) => {
            const ftrId = ftr.id;
            if (some(selectedGeos, { id: ftrId })) {
              removeGeo({ id: ftrId });
            } else {
              addGeo({ id: ftrId, name: ftr.properties.name });
            }
          }}
          onHover={(ftr) => {
            set_hoveredFtr(ftr);
          }}
          selectedFeatures={selectedGeos.map((sg) => ({
            id: sg.id,
            geo_level: sg.geoLevel.replace("he-", ""),
          }))}
          color_label_halo="white"
          uniqId={selectionLayer === "he-state" ? "state-1" : "county-1"}
          key={selectionLayer === "he-state" ? "state-1" : "county-1"}
        />

        {refVis_blmOffices && (
          <BlmReferenceLayer
            source={"src_blm_offices"}
            layerName={"blm_offices_2020"}
            labelSource={"src_blm_labels"}
            labelLayerName={"labels"}
            color="rgb(80, 0, 0)"
          />
        )}
        {refVis_blmNlcs && (
          <BlmReferenceLayer
            source={"src_blm_nlcs"}
            layerName={"blm_nlcs_2020"}
            labelSource={"src_blm_labels"}
            labelLayerName={"labels"}
            color="rgb(137, 114, 63)"
          />
        )}
        <SearchReferenceLayer
          source={"src_blm_offices"}
          layerName={"blm_offices_2020"}
          labelSource={"src_blm_labels"}
          labelLayerName={"labels"}
          searchFeatureId={searchFeature.id}
        />
        <SearchReferenceLayer
          source={"src_blm_nlcs"}
          layerName={"blm_nlcs_2020"}
          labelSource={"src_blm_labels"}
          labelLayerName={"labels"}
          searchFeatureId={searchFeature.id}
        />
      </HeMapboxComponent>
    </Box>
  );
};

export default Map;

// function updatePopup() {
//     if (!hoveredFeatureProps) return;
//     const addOrRemove = some(selectedGeos, { id: hoveredFeatureProps.id }) ? "remove" : "add";
//     const clickOrTap = screenSize === "mobile" ? "Tap" : "Click";
//     dispatch({
//         type: actions.SET_POPUP_CONTENT,
//         content: (
//             <>
//                 <div className="dheader">{hoveredFeatureProps.name}</div>
//                 <div className="dtext">
//                     {clickOrTap} to <b>{addOrRemove}</b>
//                 </div>
//             </>
//         ),
//     });
// }
