import { useContext } from "react";

import appContext from "../context/appContext";
import { useViewport } from "@headwaters-economics/web-shared";
import coverPhoto from "../assets/BLM_Employee.jpg";
import Search from "../components/Search";
import blmLogo from "../assets/Logo_BLM-Color.svg";
import { Box, Image, Stack, Text } from "grommet";

const StartScreen = () => {
  const { screenSize } = useViewport();
  const { isStartScreenVisible } = useContext(appContext);

  if (!isStartScreenVisible) return null;

  const IntroText = () => {
    return (
      <Box direction="column">
        <Text color="light-1" size="xxlarge" weight="bold">
          Get socioeconomic profiles.
        </Text>
        <Text color="light-1">
          Download reports for states and counties near Bureau of Land
          Management units.
        </Text>
      </Box>
    );
  };

  return (
    <Stack fill background={"dark-1"}>
      <Box /* background cover image */
        fill
        background={{ size: "cover", image: "url(" + coverPhoto + ")" }}
      />
      <Box /* background cover image overlay*/
        fill
        background={'linear-gradient(180deg,#7e99bb,hsla(0,0%,39%,0) 30%),linear-gradient(180deg,rgba(0,0,0,.25),rgba(0,0,0,.25))'}
      />
      <Box fill>
        <Box pad={{ top: "xlarge", horizontal: "xlarge" }}>
          <Box gap="small" width={"xlarge"} alignSelf="center">
            <Box /* Image and Intro Text*/>
              <Box direction={screenSize !== "mobile" ? "row" : "column"}>
                <Box
                  align="center"
                  justify="center"
                  height="xsmall"
                  width="xsmall"
                >
                  <Image alignSelf="center" fill src={blmLogo} />
                </Box>
                <IntroText />
              </Box>
            </Box>
            <Search />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};
export default StartScreen;
