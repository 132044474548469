import { Box, Button, DropButton, RadioButtonGroup, ThemeContext } from "grommet";
import styled from "styled-components";

const StyledWideRadioButtonGroup = styled(RadioButtonGroup)`
  label {
    width: 100%;
  }
  label > div {
    width: 100%;
  }
`;

const WideRadioButtonGroup = (props) => {
  return (
    <StyledWideRadioButtonGroup
      pad={{ vertical: "small" }}
      direction="column"
      {...props}
    >
      {(option, { checked, focus, hover }) => {
        let background;
        if (checked) background = "brand";
        else if (focus || hover) background = "light-4";
        else background = "transparent";
        return (
          <Box background={background} border pad="xsmall" round="3px">
            {option.label}
          </Box>
        );
      }}
    </StyledWideRadioButtonGroup>
  );
};

const ColoredButton = (props) => {
  return (
    <ThemeContext.Extend
      value={{
        button: { default: { border: { radius: "5px" } } },
      }}
    >
      <Button
        active
        {...props}
      />
    </ThemeContext.Extend>

  );
};
const ButtonWithDrop = (props) => {
  return (
    <ThemeContext.Extend
      value={{
        button: { default: { border: { radius: "5px" } } },
      }}
    >
       <DropButton
       active
          {...props}
        />
    </ThemeContext.Extend>

  );
};

export { WideRadioButtonGroup, ColoredButton, ButtonWithDrop };
