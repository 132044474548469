import React from "react";
import { Grommet, Box } from "grommet";
import GoogleAnalytics from "@headwaters-economics/web-shared/components/GoogleAnalytics";
import { HeMainTheme, ViewportProvider } from "@headwaters-economics/web-shared";
import AppProvider from "./context/AppProvider";
import PrimaryScreen from "./screens/PrimaryScreen";

import StartScreen from "./screens/StartScreen";


function App({ storybookState }) {
  return (
    <AppProvider storybookState={storybookState}>
      <ViewportProvider>
        {/* <Layout /> */}
        <Grommet full theme={HeMainTheme} className="app">
          <GoogleAnalytics gaAccount={"headwaterseconomics"} />

          <Box fill>
            <StartScreen />
            <PrimaryScreen />
            {/* <Main
              screenSize={screenSize}
              appHeight={appHeight}
              appWidth={appWidth}
            /> */}
            {/* Testing */}
            {/* <StartScreen />
            <PrimaryScreen /> */}
          </Box>
        </Grommet>
      </ViewportProvider>
    </AppProvider>
  );
}

export default App;
