import React, { useEffect, useReducer } from "react";

import AppContext from "./appContext";
import actions from "./actions";

import { useExtentOfGeos } from "@headwaters-economics/web-shared";

import some from "lodash/some";
import filter from "lodash/filter";

const makeGeo = (id, name, geoLevel) => {
  return {
    id: id,
    label: name,
    name: name,
    geoLevel: geoLevel.indexOf("he-") === 0 ? geoLevel : "he-" + geoLevel,
  };
};

const initialState = {
  isStartScreenVisible: true,
  isSearchFocusedOnce: false,
  mapExtent: {
    minLat: 24,
    minLon: -133,
    maxLat: 50,
    maxLon: -64,
  },
  stateFilter: null,
  // searchData: getSearchData(null, "blm-office"),
  searchType: "blm-office",
  selectedGeos: [],
  searchFeature: null,
  popupContent: null,
  hoveredFeatureProps: null,
  highlightedGeo: null,
  selectionLayer: "he-state",
  benchmarkGeo: { id: "0", label: "United States" },
  refVis_blmOffices: false,
  refVis_blmNlcs: false,
  newExtentGeos: null,
  isDownloadModalOpen: false,
};

const AppProvider = ({ children, storybookState }) => {
  const [state, dispatch] = useReducer(
    appReducer,
    storybookState ? { ...initialState, ...storybookState } : initialState
  );
  const { extentOfGeos: searchFeatureExtent } = useExtentOfGeos({
    geoIDs: state.newExtentGeos,
  });

  useEffect(() => {
    if (searchFeatureExtent)
      dispatch({ type: actions.SET_MAP_EXTENT, to: searchFeatureExtent });
  }, [searchFeatureExtent]);

  const setSearchType = (newType)=>{
    dispatch({type: actions.SET_SEARCH_TYPE_TO,to: newType});
  }

  const setStateFilter = (newState)=>{
    dispatch({type: actions.SET_STATE_FILTER_TO,to: newState});
  }

  const setSearchFeature = (newFeature)=>{
    dispatch({type:actions.SET_SEARCH_FEATURE_TO, to:newFeature})
  }
  const addGeo = (newFeature)=>{
    dispatch({type:actions.ADD_GEO, ftr:newFeature})
  }
  const removeGeo = (newFeature)=>{
    dispatch({type:actions.REMOVE_GEO, ftr:newFeature})
  }
  const setRefVisBlmNlcs= (newState)=>{
    dispatch({type:actions.SET_REFVIS_BLM_NLCS, to: newState})
  }
  const setRefVisBlmOffices= (newState)=>{
    dispatch({type:actions.SET_REFVIS_BLM_OFFICES, to: newState})
  }
  const setSelectionLayer = (newLayer)=>{
    dispatch({type:actions.SET_SELECTION_LAYER_TO, to:newLayer})
  }
  const setIsDownloadModalOpen = (isOpen) => {
    dispatch({ type: actions.SET_IS_DOWNLOAD_MODAL_OPEN, to: isOpen });
  }
  const clearGeos = () => {
    dispatch({ type: actions.CLEAR_GEOS });
  }

  return (
    <AppContext.Provider
      value={{
        isStartScreenVisible: state.isStartScreenVisible,
        searchType: state.searchType,
        setSearchType: setSearchType,
        stateFilter: state.stateFilter,
        setStateFilter: setStateFilter,
        searchFeature: state.searchFeature,
        setSearchFeature: setSearchFeature,
        selectedGeos:state.selectedGeos,
        addGeo: addGeo,
        removeGeo: removeGeo,
        refVis_blmNlcs: state.refVis_blmNlcs,
        refVis_blmOffices: state.refVis_blmOffices,
        setRefVisBlmNlcs: setRefVisBlmNlcs,
        setRefVisBlmOffices: setRefVisBlmOffices,
        setSelectionLayer:setSelectionLayer,
        selectionLayer: state.selectionLayer,
        isDownloadModalOpen: state.isDownloadModalOpen,
        setIsDownloadModalOpen,
        benchmarkGeo: state.benchmarkGeo,
        clearGeos,
        mapExtent: state.mapExtent
        // dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const appReducer = (state, action) => {
  let selectedGeos;
  let selectionLayer;

  switch (action.type) {
    case actions.SET_SEARCH_FEATURE_TO:
      selectionLayer = "he-county";
      if (
        action.to.geo_level === "office" ||
        action.to.geo_level === "nlcs"
      ) {
        selectedGeos = action.to.app_data.geos.map((geo) => {
          if (geo.geo_level === "state") {
            selectionLayer = "he-state";
          }
          return makeGeo(geo.id, geo.name, geo.geo_level);
        });
      } else {
        selectedGeos = [
          makeGeo(action.to.id, action.to.label, action.to.geo_level),
        ];
      }

      return {
        ...state,
        isStartScreenVisible: false,
        searchFeature: action.to,
        selectedGeos,
        selectionLayer,
        newExtentGeos: selectedGeos.map((geo) => geo.id),
      };
    case actions.SET_STATE_FILTER_TO:
      return {
        ...state,
        stateFilter: action.to,
        // searchData: getSearchData(action.to, state.searchType),
      };
    case actions.REMOVE_GEO:
      selectedGeos = filter(state.selectedGeos, (geo) => geo.id !== action.ftr.id);

      return {
        ...state,
        selectedGeos: selectedGeos,
      };
    case actions.ADD_GEO:
      //check to make sure the
      if (some(state.selectedGeos, { id: action.ftr.id })) return { ...state };
      selectedGeos = state.selectedGeos.concat(
        makeGeo(action.ftr.id, action.ftr.name, state.selectionLayer)
      );
      return {
        ...state,
        selectedGeos: selectedGeos,
      };
    case actions.CLEAR_GEOS:
      selectedGeos = [];

      return {
        ...state,
        selectedGeos: selectedGeos,
      };
    case actions.SET_POPUP_CONTENT:
      return {
        ...state,
        popupContent: action.content,
      };
    case actions.SET_HOVERED_FEATURE_PROPS:
      return {
        ...state,
        hoveredFeatureProps: action.hoveredFeatureProps,
      };

    case actions.SET_BENCHMARK_TO:
      return {
        ...state,
        benchmarkGeo: action.to,
      };
    case actions.SET_SEARCH_TYPE_TO:
      return {
        ...state,
        searchType: action.to,
        // searchData: getSearchData(state.stateFilter, action.to),
        stateFilter:
          action.to === "he-state"
            ? initialState.stateFilter
            : state.stateFilter,
      };
    case actions.SET_MAP_EXTENT:
      return {
        ...state,
        mapExtent: action.to,
      };
    case actions.SET_SELECTION_LAYER_TO:
      return {
        ...state,
        selectionLayer: action.to,
      };
    case actions.SET_REFVIS_BLM_OFFICES:
      return {
        ...state,
        refVis_blmOffices: !state.refVis_blmOffices,
      };
    case actions.SET_REFVIS_BLM_NLCS:
      return {
        ...state,
        refVis_blmNlcs: !state.refVis_blmNlcs,
      };
    case actions.SET_SEARCH_FOCUSED:
      return {
        ...state,
        isSearchFocusedOnce: true,
      };
    case actions.RETURN_TO_START:
      return {
        ...state,
        isStartScreenVisible: true,
        isSearchFocusedOnce: false,
      };
      case actions.SET_IS_DOWNLOAD_MODAL_OPEN:
        return {
          ...state,
          isDownloadModalOpen: action.to,
        };
    default:
      throw new Error();
  }
};

export default AppProvider;
