import React, { useContext } from "react";
import appContext from "../../context/appContext";
import { ControlContainer } from "@headwaters-economics/web-shared/components/Map";
import {
  Radial,
  RadialSelected,
  Checkbox,
  CheckboxSelected,
} from "grommet-icons";

import { Box, Grid, Text } from "grommet";

const Swatch = ({ fillColor, borderColor, borderSize = "1px" }) => {
  return (
    <Box fill pad="xxsmall">
      <Box
        fill
        background={fillColor}
        border={{ color: borderColor, size: borderSize }}
      />
    </Box>
  );
};

const LegendItem = ({ icon, label, swatch, onIconClick }) => {
  return (
    <Grid columns={["auto", "180px", "40px"]} height="25px">
      <Box
        justify="center"
        pad={{ left: "xsmall", right: "xsmall" }}
        align="end"
      >
        {icon}
      </Box>
      <Box justify="center">{label}</Box>
      <Box alignSelf="center" height={"20px"}>
        {swatch}
      </Box>
    </Grid>
  );
};

const SectionHeader = ({ label }) => {
  return (
    <Text size="small" color="brand" weight={"bold"}>
      {label}
    </Text>
  );
};

const Legend = () => {
  const {
    selectionLayer,
    refVis_blmOffices,
    refVis_blmNlcs,
    setRefVisBlmOffices,
    setRefVisBlmNlcs,
    setSelectionLayer,
  } = useContext(appContext);


  return (
    <ControlContainer position="lower-left">
      <Box>
        <SectionHeader label={"SELECTION LAYER"} />
        <LegendItem
          icon={
            selectionLayer === "he-county" ? (
              <RadialSelected size="16px" />
            ) : (
              <Radial
                size="16px"
                onClick={() => {
                  setSelectionLayer("he-county");
                }}
              />
            )
          }
          label="County"
          swatch={
            selectionLayer === "he-county" && (
              <Swatch
                fillColor={"rgba(176, 176, 176,0.4)"}
                borderColor={"rgb(176, 176, 176)"}
              />
            )
          }
        />
        <LegendItem
          icon={
            selectionLayer === "he-state" ? (
              <RadialSelected size="16px" />
            ) : (
              <Radial
                size="16px"
                onClick={() => {
                  setSelectionLayer("he-state");
                }}
              />
            )
          }
          label="State"
          swatch={
            selectionLayer === "he-state" && (
              <Swatch
                fillColor={"rgba(176, 176, 176,0.4)"}
                borderColor={"rgb(176, 176, 176)"}
              />
            )
          }
        />

        <SectionHeader label={"BLM REFERENCE LAYERS"} />

        <LegendItem
          icon={<CheckboxSelected size="16px" color="light-4" />}
          label="All BLM Lands"
          swatch={
            <Swatch
              fillColor={"rgba(240, 209, 117, 0.5)"}
              borderColor={"transparent"}
            />
          }
        />

        <LegendItem
          icon={
            refVis_blmOffices ? (
              <CheckboxSelected
                size="16px"
                onClick={() => {
                  setRefVisBlmOffices(!refVis_blmOffices);
                }}
              />
            ) : (
              <Checkbox
                size="16px"
                onClick={() => {
                  setRefVisBlmOffices(!refVis_blmOffices);
                }}
              />
            )
          }
          label="Field Office"
          swatch={
            refVis_blmOffices && (
              <Swatch
                fillColor={"transparent"}
                borderColor={"rgb(80, 0, 0)"}
                borderSize="2px"
              />
            )
          }
        />

        <LegendItem
          icon={
            refVis_blmNlcs ? (
              <CheckboxSelected
                size="16px"
                onClick={() => {
                  setRefVisBlmNlcs(!refVis_blmNlcs);
                }}
              />
            ) : (
              <Checkbox
                size="16px"
                onClick={() => {
                  setRefVisBlmNlcs(!refVis_blmNlcs);
                }}
              />
            )
          }
          label="National Conservation Land"
          swatch={
            refVis_blmNlcs && (
              <Swatch
                fillColor={"transparent"}
                borderColor={"rgb(137, 114, 63)"}
                borderSize="2px"
              />
            )
          }
        />

      </Box>
    </ControlContainer>
  );
};
export default Legend;
