import { useContext } from "react";

import appContext from "../context/appContext";
import { Box, Image, Stack } from "grommet";
import Map from "../components/Map/Map";
import logo from "../assets/Logo_BLM-Color.svg";
import Sidebar from "../components/Sidebar";
import DownloadModal from "../components/DownloadModal";

const PrimaryScreen = () => {
  const { isStartScreenVisible } = useContext(appContext);

  if (isStartScreenVisible) return null;

  return (
    <Box fill>
      <Box
        direction="row"
        align="center"
        justify="between"
        background="dark-2"
        height={"60px"}
      >
        <Box direction="row" gap="small" pad="xsmall">
          <Box>
            <Image src={logo} height={"30px"} />
          </Box>
          <Box>Bureau of Land Management Socioeconomic Profile Tool</Box>
        </Box>
      </Box>

      <Box fill>
        <Stack fill anchor="top-right">
          <Map />
          <Sidebar />
        </Stack>
      </Box>
      <DownloadModal />
    </Box>
  );
};
export default PrimaryScreen;
