import { useContext, useRef } from "react";
import { useViewport } from "@headwaters-economics/web-shared";
import appContext from "../context/appContext";
import EPSForm from "@headwaters-economics/web-shared/components/EpsForm";
import { Box, Footer, Header, Heading, Layer } from "grommet";
import { Download as DownloadIcon } from "grommet-icons";
import { ColoredButton } from "./Buttons";

const DownloadModal = () => {
  const {
    isDownloadModalOpen,
    setIsDownloadModalOpen,
    selectedGeos,
    searchFeature,
    benchmarkGeo,
    setBenchmark,
  } = useContext(appContext);

  const epsFormRef = useRef();
  const { screenSize } = useViewport();
  if (!isDownloadModalOpen) return null;
  return (
    <Layer
      onEsc={() => setIsDownloadModalOpen(false)}
      onClickOutside={() => setIsDownloadModalOpen(false)}
      plain
    >
      <Box background='white' gap="small" width={"large"} round='small'  elevation="large">
        <Header pad={{vertical:"xsmall",horizontal:'medium'}} justify="start" border="bottom" background={'brand'} round={{size:'small',corner:'top'}}>
          <DownloadIcon />
          <Heading size="large" >Download Report</Heading>
        </Header>
        <Box pad={"small"} gap="small">
          <EPSForm
            disabled={selectedGeos.length === 0}
            pdfOnly={screenSize==='mobile'}
            TemplateName={'blm'}
            requestSource="eps-blm"
            excelOnly={selectedGeos.length > 5}
            Group1_geoCodes={selectedGeos.map((geo) => {
              return geo.id;
            })}
            Group1_Aggregate={false}
            Group1_Region={false}
            Group1_Benchmark={false}
            Group3_overrideName={benchmarkGeo.label}
            defaultTitle="BLM Socioeconomic Profile"
            defaultSubTitle={searchFeature.label}
            ref={epsFormRef}
            benchmarkGeo={benchmarkGeo}
            onBenchmarkGeoUpdate={setBenchmark}
          />
        </Box>
        <Footer pad={"small"} border={"top"} justify="end">
          <ColoredButton
            label="Download"
            color='brand'
            // disabled={benchmarkGeo.id === "0"}
            onClick={() => {
              if (epsFormRef.current) {
                epsFormRef.current.submitViaCors();
              }
            }}
          />
          <ColoredButton
            label="Close"
            onClick={() => setIsDownloadModalOpen(false)}
            color='dark-1'
          />
        </Footer>
      </Box>
    </Layer>
  );
};
export default DownloadModal;
